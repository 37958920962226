import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';

import {
  apiAssignFlightNumbersDefaultAction,
  apiAssignFlightNumbersFromReferenceScheduleAction,
  apiFlightNumbersAssignmentFailedAction,
  apiFlyingLinesCreateTargetScheduleSuccess,
  apiFlyingLinesFlightNumbersAssigned,
  apiScheduleSettingsLinearityChangedFailed,
  apiScheduleSettingsLinearityChangedSuccess,
  apiScheduleSettingsSectorCapsAddedFailed,
  apiScheduleSettingsSectorCapsAddedSuccess,
  apiScheduleSettingsSectorCapsDeletedFailed,
  apiScheduleSettingsSectorCapsDeletedSuccess,
  apiScheduleSettingsSectorCapsUpdatedFailed,
  apiScheduleSettingsSectorCapsUpdatedSuccess,
  apiScheduleSettingsUpdateScheduleInfoFailed,
  apiScheduleSettingsUpdateScheduleInfoSuccess,
  changeSchedulePrivacyButtonClicked,
  changeSchedulePrivacyFailed,
  changeSchedulePrivacySuccess,
  scheduledCellDaysAPIFetchingFailed,
  scheduledCellDaysAPIFetchingSuccess,
  scheduledCellHovered,
  scheduleSettingsCapAPIFetchingFailed,
  scheduleSettingsCapAPIFetchingSuccess,
  scheduleSettingsCapStarted,
  scheduleSettingsHistoricSlotsRetimingChanged,
  scheduleSettingsLinearityChanged,
  scheduleSettingsOptimiserBranchChanged,
  scheduleSettingsOptimiserObjectiveChanged,
  scheduleSettingsOptimiserRuntimeMinutesChanged,
  scheduleSettingsProgressionMinsChanged,
  scheduleSettingsSectorCapsAdded,
  scheduleSettingsSectorCapsDeleted,
  scheduleSettingsSectorCapsUpdated,
  scheduleSettingsSlotOverridesCheckboxChanged,
  scheduleSettingsSlotProfileChanged
} from './schedules.actions';
import { Store } from '@ngrx/store';
import { selectCurrentSchedule, selectSeason } from '@state/app.selectors';
import { catchError, concatMap, finalize, map, of, switchMap, tap } from 'rxjs';
import { FlyingLinesHttpService } from '@features/schedules/flying-lines/services/flying-lines.http.service';
import { ToastrService } from 'ngx-toastr';
import {
  scheduleDetailsPageStart,
  scheduleFetchedFailedOnScheduleDetailsPage,
  scheduleFetchedOnScheduleDetailsPage,
  scheduleFetchedOptimiserObjectiveFailed,
  scheduleFetchedOptimiserObjectiveSuccess
} from '@state/app.actions';
import { ScheduleDetailsHttpService } from '../schedule-details/schedule-details.http.service';
import { selectCurrentRouteState } from '@state/router.selector';
import { SchedulesMainHttpService } from '../schedules-main/schedules-main.http.service';
import { SchedulesSettingsHttpService } from '../schedule-settings/schedules-settings.http.service';
import { ModalService } from '@shared/components/dialogs/modal.service';

@Injectable()
export class SchedulesEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private flyingLinesHttpService: FlyingLinesHttpService,
    private scheduleDetailsHttpService: ScheduleDetailsHttpService,
    private schedulesMainHttpService: SchedulesMainHttpService,
    private modalService: ModalService,
    private schedulesSettingsService: SchedulesSettingsHttpService,
    private toastr: ToastrService
  ) {}

  getScheduleInfo = createEffect(() => {
    return this.actions$.pipe(
      ofType(scheduleDetailsPageStart, apiFlyingLinesCreateTargetScheduleSuccess),
      concatLatestFrom(() => this.store.select(selectCurrentRouteState)),
      switchMap(([action, routeState]) => {
        const scheduleId =
          action.type === apiFlyingLinesCreateTargetScheduleSuccess.type ? action.schedule.id : routeState.params.id;
        return this.scheduleDetailsHttpService.getScheduleInfo(scheduleId, routeState.params.season).pipe(
          map((schedule) => scheduleFetchedOnScheduleDetailsPage({ schedule })),
          catchError(() => {
            return of(scheduleFetchedFailedOnScheduleDetailsPage());
          })
        );
      })
    );
  });

  getScheduleInfoOptimiserObjective = createEffect(() => {
    return this.actions$.pipe(
      ofType(scheduleDetailsPageStart),
      concatLatestFrom(() => this.store.select(selectCurrentRouteState)),
      switchMap(([_, routeState]) => {
        return this.scheduleDetailsHttpService.getScheduleInfoOptimiserObjective(routeState.params.season).pipe(
          map((scheduleOptimiserObjective) => scheduleFetchedOptimiserObjectiveSuccess({ scheduleOptimiserObjective })),
          catchError(() => {
            return of(scheduleFetchedOptimiserObjectiveFailed());
          })
        );
      })
    );
  });

  changeSchedulePrivacy = createEffect(() => {
    return this.actions$.pipe(
      ofType(changeSchedulePrivacyButtonClicked),
      concatLatestFrom(() => this.store.select(selectSeason)),
      switchMap(([action, season]) => {
        this.modalService.showLoader();
        return this.schedulesMainHttpService.updateSchedulePrivacy(action.schedule, season, action.is_private).pipe(
          tap(() => {
            this.modalService.showConfirmDialog('Schedule privacy updated successfully.', 'Ok', null, false);
          }),
          map(() => changeSchedulePrivacySuccess()),
          catchError(() => {
            return of(changeSchedulePrivacyFailed());
          }),
          finalize(() => this.modalService.hideLoader())
        );
      })
    );
  });

  assignFlightNumbersDefaultEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(apiAssignFlightNumbersDefaultAction),
      concatLatestFrom(() => this.store.select(selectCurrentSchedule)),
      switchMap(([_action, currentSchedule]) => {
        this.toastr.success('Assigning flight numbers.');
        return this.flyingLinesHttpService.assignFlightNumbers(currentSchedule.id).pipe(
          tap(() => {
            this.toastr.success('Flight numbers successfully assigned.');
          }),
          map(() => apiFlyingLinesFlightNumbersAssigned()),
          catchError(() => {
            this.toastr.error('Error occurred during flight number assignment.');
            return of(apiFlightNumbersAssignmentFailedAction());
          })
        );
      })
    );
  });

  assignFlightNumbersFromReferenceScheduleEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(apiAssignFlightNumbersFromReferenceScheduleAction),
      concatLatestFrom(() => this.store.select(selectCurrentSchedule)),
      switchMap(([_action, currentSchedule]) => {
        this.toastr.success('Assigning flight numbers.');
        return this.flyingLinesHttpService
          .assignFlightNumbersFromOtherSchedule(currentSchedule.id, _action.referenceScheduleId)
          .pipe(
            tap(() => {
              this.toastr.success('Flight numbers successfully assigned using a reference schedule.');
            }),
            map(() => apiFlyingLinesFlightNumbersAssigned()),
            catchError(() => {
              this.toastr.error('Error occurred during flight number assignment using a reference schedule.');
              return of(apiFlightNumbersAssignmentFailedAction());
            })
          );
      })
    );
  });

  scheduleSettingsUpdateScheduleInfoEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        scheduleSettingsSlotProfileChanged,
        scheduleSettingsSlotOverridesCheckboxChanged,
        scheduleSettingsHistoricSlotsRetimingChanged,
        scheduleSettingsProgressionMinsChanged,
        scheduleSettingsOptimiserRuntimeMinutesChanged,
        scheduleSettingsOptimiserObjectiveChanged,
        scheduleSettingsOptimiserBranchChanged
      ),
      concatLatestFrom(() => [this.store.select(selectCurrentSchedule), this.store.select(selectSeason)]),
      concatMap(([action, scheduleInfo, season]) => {
        const updatedScheduleInfo = { ...scheduleInfo };

        // Apply the update based on action type
        switch (action.type) {
          case scheduleSettingsSlotProfileChanged.type:
            updatedScheduleInfo.growth_slot_profile_id = action.value;
            break;
          case scheduleSettingsSlotOverridesCheckboxChanged.type:
            updatedScheduleInfo[action.checkboxType] = action.value;
            if (action.checkboxType === 'allow_historic_slots_after_curfew' && !action.value) {
              updatedScheduleInfo.historic_slots_after_curfew_can_be_retimed = false;
            }
            break;
          case scheduleSettingsHistoricSlotsRetimingChanged.type:
            updatedScheduleInfo.historic_slots_retiming = action.value;
            break;
          case scheduleSettingsProgressionMinsChanged.type:
            updatedScheduleInfo.min_max_progression_minutes = action.value;
            break;
          case scheduleSettingsOptimiserRuntimeMinutesChanged.type:
            updatedScheduleInfo.optimiser_runtime_minutes = action.value;
            break;
          case scheduleSettingsOptimiserObjectiveChanged.type:
            updatedScheduleInfo.optimiser_objective = action.value;
            break;
          case scheduleSettingsOptimiserBranchChanged.type:
            updatedScheduleInfo.optimiser_branch = action.value;
            break;
        }

        return this.schedulesMainHttpService.updateSchedule(updatedScheduleInfo, season).pipe(
          map((schedule) => {
            this.toastr.success(`${action.message}`);
            return apiScheduleSettingsUpdateScheduleInfoSuccess({ schedule });
          }),
          catchError(() => {
            this.toastr.error('Update failed');
            return of(apiScheduleSettingsUpdateScheduleInfoFailed());
          })
        );
      })
    );
  });

  scheduleSettingsLinearityUpdateEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(scheduleSettingsLinearityChanged),
      concatLatestFrom(() => this.store.select(selectCurrentRouteState)),
      switchMap(([action, routeState]) => {
        return this.schedulesSettingsService
          .updateScheduleLinearity(routeState.params.id, routeState.params.season, action.scheduleLinearity)
          .pipe(
            map((scheduleLinearity) => {
              this.toastr.success('Linearity updated successfully.');
              return apiScheduleSettingsLinearityChangedSuccess({ scheduleLinearity });
            }),
            catchError(() => {
              return of(apiScheduleSettingsLinearityChangedFailed());
            })
          );
      })
    );
  });

  getScheduleSettingsSectorCapsEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(scheduleSettingsCapStarted),
      concatLatestFrom(() => this.store.select(selectCurrentRouteState)),
      switchMap(([, routeState]) => {
        return this.schedulesSettingsService.getScheduleSectorCaps(routeState.params.id, routeState.params.season).pipe(
          map((scheduleSettingsCap) => scheduleSettingsCapAPIFetchingSuccess({ scheduleSettingsCap })),
          catchError(() => of(scheduleSettingsCapAPIFetchingFailed()))
        );
      })
    );
  });

  scheduleSettingsSectorCapsAddEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(scheduleSettingsSectorCapsAdded),
      concatLatestFrom(() => this.store.select(selectCurrentRouteState)),
      switchMap(([action, routeState]) => {
        return this.schedulesSettingsService
          .addScheduleSectorCap(routeState.params.id, routeState.params.season, action.scheduleSettingsCap)
          .pipe(
            map((scheduleSettingsCap) => {
              this.toastr.success('Sector caps created successfully.');
              return apiScheduleSettingsSectorCapsAddedSuccess({ scheduleSettingsCap });
            }),
            catchError(() => {
              this.toastr.error('Failed to create sector cap.');
              return of(apiScheduleSettingsSectorCapsAddedFailed());
            })
          );
      })
    );
  });

  scheduleSettingsSectorCapsUpdateEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(scheduleSettingsSectorCapsUpdated),
      concatLatestFrom(() => this.store.select(selectCurrentRouteState)),
      switchMap(([action, routeState]) => {
        return this.schedulesSettingsService
          .updateScheduleSectorCap(
            routeState.params.id,
            action.scheduleSettingsCap.id,
            routeState.params.season,
            action.scheduleSettingsCap
          )
          .pipe(
            map((scheduleSettingsCap) => {
              this.toastr.success('Sector cap updated successfully.');
              return apiScheduleSettingsSectorCapsUpdatedSuccess({ scheduleSettingsCap });
            }),
            catchError(() => {
              this.toastr.error('Failed to update sector cap.');
              return of(apiScheduleSettingsSectorCapsUpdatedFailed());
            })
          );
      })
    );
  });

  scheduleSettingsSectorCapsDeleteEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(scheduleSettingsSectorCapsDeleted),
      concatLatestFrom(() => this.store.select(selectCurrentRouteState)),
      switchMap(([action, routeState]) => {
        return this.schedulesSettingsService
          .deleteScheduleSectorCap(routeState.params.id, routeState.params.season, action.sectorCapId)
          .pipe(
            map(() => {
              this.toastr.success('Sector cap deleted successfully.');
              return apiScheduleSettingsSectorCapsDeletedSuccess();
            }),
            catchError(() => {
              this.toastr.error('Failed to delete sector cap.');
              return of(apiScheduleSettingsSectorCapsDeletedFailed());
            })
          );
      })
    );
  });

  schedulePlanScheduledDaysFetchEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(scheduledCellHovered),
      concatLatestFrom(() => this.store.select(selectCurrentRouteState)),
      switchMap(([action, routeState]) => {
        return this.schedulesSettingsService
          .getScheduledDays(routeState.params.id, routeState.params.season, action.marketId)
          .pipe(
            map((scheduledDays: number[]) => scheduledCellDaysAPIFetchingSuccess({ scheduledDays })),
            catchError(() => of(scheduledCellDaysAPIFetchingFailed()))
          );
      })
    );
  });
}
