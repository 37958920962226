import { SeasonCode } from '@shared/models/seasons.model';
import { Category } from '@features/spreads/components/categories/categories.model';
import { DutyLimitationsState } from '@features/duty-limitations/state/duty-limitations.state';
import { ScheduleInfo } from '@features/schedules/schedules.models';
import { AirlineOperatingCertificate } from '@features/settings/airline-operating-certificate/airline-operating-certificate.model';
import { Base } from '@features/engineering/engineering.model';
import { ConnectionStatus } from '@shared/services/connection.service';
import { LoginUserDataDto } from '@authorization/services/auth-token.models';

export interface AppState {
  seasonCode: SeasonCode;
  currentSchedule: ScheduleInfo | null;
  previousSchedule: ScheduleInfo;
  categories: Category[];
  seasonSelectorDisabled: boolean;
  dutyLimitations: DutyLimitationsState;
  airlineOperatingCertificates: AirlineOperatingCertificate[] | null;
  allSchedules: ScheduleInfo[];
  bases: Base[];
  isFullscreen: boolean;
  user: LoginUserDataDto | null;
  dragImage: HTMLElement;
  internetConnectionStatus: ConnectionStatus;
}

export const initialState: AppState = {
  seasonCode: 'S25',
  currentSchedule: null,
  previousSchedule: null,
  categories: [],
  seasonSelectorDisabled: false,
  dutyLimitations: null,
  airlineOperatingCertificates: null,
  allSchedules: [],
  bases: null,
  isFullscreen: false,
  user: null,
  dragImage: null,
  internetConnectionStatus: 'unknown'
};
